<template>
  <div class="p-2">
    <div v-if="list.length" class="overflow-auto">
      <table class="table table-sm">
        <thead>
          <tr>
            <th></th>
            <th>№</th>
            <th>№ СЦ</th>
            <th>СЦ</th>
            <th>создан</th>
            <th>инструмент</th>
            <th>группа</th>
            <th>S№</th>
            <th>дата продажи</th>
            <th>дата принятия в ремонт</th>
            <th>дефект</th>
            <th>продавец</th>
            <th>
              <div>последнее</div>
              <div>сообщение</div>
            </th>
          </tr>
        </thead>
        <tr
          v-for="(item, idx) in list"
          :key="idx"
          @click="goRepair(item.remont_id)"
          :class="[item.bg, item.text]"
        >
          <td>{{ idx + 1 }}</td>
          <td>{{ item.repair_number }}</td>
          <td>{{ item.receiptNumber }}</td>
          <td>{{ item.user }}</td>
          <td>{{ item.date_add }}</td>
          <td>{{ item.name_instr }}</td>
          <td>{{ item.serialNumberGroup }}</td>
          <td>{{ item.serialNumber }}</td>
          <td>{{ item.dateSale }}</td>
          <td>{{ item.dateRepair }}</td>
          <td>{{ item.defect }}</td>
          <td>{{ item.seller }}</td>
          <td>{{ item.last_message }}</td>
        </tr>
      </table>
    </div>
    <div v-else class="text-center">пока нет ремонтов</div>
  </div>
</template>
<script>
import { HTTPGET, HTTPGETNOLOADER } from "@/api";
export default {
  name: "ConsultationRepairs",
  data: function() {
    return {
      list: [],
      interval: null
    };
  },
  mounted() {
    this.get_data();
    this.interval = setInterval(() => {
      this.get_data_no_loader();
    }, 10000);
  },
  methods: {
    get_data() {
      HTTPGET({
        url: "repairs/consultation_list.php"
      }).then(response => (this.list = response.data.list));
    },
    goRepair(id) {
      this.$router.push(`/repairs/repair/${id}?path=консультация`);
    },
    get_data_no_loader() {
      HTTPGETNOLOADER({
        url: "repairs/consultation_list.php"
      }).then(response => (this.list = response.data.list));
    }
  },
  destroyed() {
    clearInterval(this.interval);
  }
};
</script>

<style scoped>
table {
  font-size: 0.7em;
}
th {
  text-align: center;
}
tr {
  cursor: pointer;
}
</style>
